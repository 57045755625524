import * as React from "react"

import Layout from "@/components/Layout"
import Seo from "@/components/seo"
import HeroSection from "@/components/HeroSection"
import OurWorkHomeContainer from "@/components/OurWorkHomeContainer"
import TechStackContainer from "@/components/TechStackContainer"
import Col3ServicesContainer from "@/components/Col3ServicesContainer"
import CallToActionMini from "@/components/CallToActionMini"
import WorkFlow from "@/components/OurProcess/work-flow"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Independent Cloud Solution Agency: Hire Us for Your Business"
      description="As an independent cloud solution agency, we offer unbiased and expert services to help your business succeed. From cloud infrastructure and application development to training and support, we have the skills and experience to guide you every step of the way. Trust us as your partners in the cloud. Contact us to learn more and take the first step towards success."
    />
    <HeroSection></HeroSection>
    <OurWorkHomeContainer></OurWorkHomeContainer>
    <Col3ServicesContainer></Col3ServicesContainer>
    <TechStackContainer></TechStackContainer>
    {/* <OurProcess page="Home" /> */}
    <WorkFlow />
    <CallToActionMini
      title="What we do?"
      description="Take the first step by transforming your digital foothold using our Containers with Kubernetes service!"
    />
  </Layout>
)

export default IndexPage
